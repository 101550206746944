import React from 'react';

import {
  Layout,
  HeroGeneric,
  CoachingBanner,
  ContactFormBanner,
  SEO,
  CoachingBlogBanner,
} from '../components';

export default function Coaching(props) {
  return (
    <Layout {...props}>
      <SEO title="Coaching" pathname={props.location.pathname} />
      <HeroGeneric title="Coaching" />
      <CoachingBanner />
      <CoachingBlogBanner />
      <ContactFormBanner />
    </Layout>
  );
}
